import { Button, Card, Col, Container, Form, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import {
  useGetFiltersQuery,
  useUpdateFileMutation,
  useGetFilesxfiltersMutation
} from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  getFiltersTkr,
  getCustomFilters,
  getFilterSeries,
  setFiltersTkr,
  getReports,
  setCustomFilters,
  getFiltsNav,
  getNumDo,
  setCliidxx,
  getCliidxx
} from "../../libs/redux/slices/Trkgfile/trkgfileSlice";
import { useDispatch, useSelector } from "react-redux";
import Loadingx from "../Loadingx/Loadingx";
import { useCallback, useEffect, useState } from "react";
import Modadesc from "./Modadesc";
import Metadatx from "./Metadatx";
import Linetime from "./Linetime";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getIsConfirm,
  setErrorMessage,
  setIsConfirm,
  setShowErrors,
  setTypeMessage,
} from "../../libs/redux/slices/Errorsxx/errorsSlice";
import Trkeditx from "./Trkeditx";
import { getinfoUser, getisLoggedIn, getpermUser } from "../../libs/redux/slices/Loginxxx/loginSlice";
import { useNavigate } from "react-router-dom";
import Modaview from "./Modaview";
import { useForm } from "react-hook-form";
import { useSendMailMutation } from '../../libs/redux/slices/Repoprog/RepoprogApiSlice';
import CryptoJS from 'crypto-js';
import Modalerr from "../Errorsxx/Modalerr";
import Modatipx from "./Modatipx";
import {
  useLazyGetClientReportQuery,
} from "../../libs/redux/slices/autocomxSlice/autocomxApiSlice";


/**
 * Logica Import PDFViewer
 * Ing. Carlos F. Castaneda M.
 * Ing. Ivan Castiblanco
 */
const PDF_TYPE = 'application/pdf';

const Trkgfile = () => {
  const { register } = useForm();
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [clientOptions, setClientOptions] = useState([]);
  const [getClientReport] = useLazyGetClientReportQuery();
  const [selectedYear, setSelectedYear] = useState(0);
  const months = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: new Date(0, i).toLocaleString('default', { month: 'long' }).toUpperCase()
  }));
  months.unshift({ value: 0, label: 'SELECCIONE' });
  const years = Array.from({ length: 2 }, (_, i) => ({ label: 2023 + i }));
  years.unshift({ label: 'SELECCIONE' });
  const [correos, setCorreos] = useState('');
  const limpiarCampos = () => {
    setSelectedClient(0);
    setSelectedMonth(0);
    setSelectedYear(0);
    setCorreos('');
  };
  const [sendMail] = useSendMailMutation();

  const generarReporte = async () => {
    if (!selectedClient || !selectedMonth || !selectedYear || !correos) {
      alert('Todos los campos son necesarios para enviar el correo.');
      return;
    }

    const emailData = {
      CLIIDXXX: selectedClient,
      MONTHXXX: selectedMonth,
      YEARXXXX: selectedYear,
      EMAILSXX: correos
    };

    setLoadReport(true);
    try {
      await sendMail(emailData).unwrap();
      alert('Correo enviado con éxito!');
    } catch (error) {
      console.error('Error al enviar correo:', error);
      alert('Error al enviar el correo.');
    } finally {
      setLoadReport(false);
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filters = useSelector(getFiltersTkr);
  const customFilters = useSelector(getCustomFilters);
  const seriesxFilter = useSelector(getFilterSeries);
  const filtrosNavar = useSelector(getFiltsNav);
  const reports = useSelector(getReports);
  const numDo = useSelector(getNumDo);
  const cliid = useSelector(getCliidxx);
  const { page, perPage, sorts } = customFilters;
  const [loadReport, setLoadReport] = useState(false);
  const [selAllChecked, setSelAllChecked] = useState([]);
  const [AllChecked, setAllChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [checSelc, setChecSelc] = useState([]);
  const [dataTkr, setDatatkr] = useState([]);
  const [opMetada, setOpMetada] = useState(false);
  const [metadaSelc, setMetadaSelc] = useState("");
  const [linetime, setLinetime] = useState(false);
  const [lineData, setLineData] = useState("");
  const isDelete = useSelector(getIsConfirm);
  const [dataDelete, setDataDelete] = useState(null);
  const [updateFile] = useUpdateFileMutation();
  const [modalEdit, setModalEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const permUser = useSelector(getpermUser);
  const [permissionDelete, setPermissionDelete] = useState(false);
  const [permissionUpdate, setPermissionUpdate] = useState(false);
  const [tiposPermission, setTiposPermission] = useState(null);
  const isLoggedIn = useSelector(getisLoggedIn);
  const [metadaMaqSelc, setMetadaMaqSelc] = useState("");
  const [modalView, setModalView] = useState(false);
  const [jsonFilters, setJsonFilters] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const infoUser = useSelector(getinfoUser);
  const [isDownloadTipo, setIsDownloadTipo] = useState({});
  const [checkDownload, setCheckDownload] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [openTipoDocModal, setOpenTipoDocModal] = useState(false);
  const [tipoDocs, setTipoDocs] = useState([]);
  const [selectedTipoDoc, setSelectedTipoDoc] = useState("");
  const [jsonMeta, setJsonMeta] = useState({});
  const [getFilesxfilters] = useGetFilesxfiltersMutation();
  const [isLoadings, setIsLoading] = useState(false);


  useEffect(() => {
    if (!loadReport) {
      setSelectedClient(0);
      setSelectedMonth(0);
      setSelectedYear(0);
      setCorreos('');
    }
  }, [loadReport]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    try {
      const result = await getClientReport(query).unwrap();
      const formatData = result.data.map((client) => ({
        id: client.id,
        label: client.label
      }));
      setClientOptions(formatData || []);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
      setClientOptions([]);
    } finally {
      setIsLoading(false);
    }
  };
  const [serieId, setSerieId] = useState('');
  const [hasMore, setHasMore] = useState(null);

  const { data, isLoading, isFetching } = useGetFiltersQuery({
    filters,
    reports: reports,
    page,
    per_page: perPage,
    sorts,
  });
  useEffect(() => {
    if (data && data?.data?.length > 0 && !reports) {
      const dataTkring = data.data.map((row) => {
        if (row.JSONMETX?.CLIIDXXX) {
          dispatch(setCliidxx(row.JSONMETX.CLIIDXXX))
        }
        switch (row.TYPEXXXX) {
          case "application/pdf":
            return { ...row, imgfilex: "images/Icon-PDF.png" };
          case "image/png":
          case "image/jpeg":
          case "image/jpg":
            let nomFile = row.PATHFILX.split("/").at(-1);
            let fileprev = nomFile.split(".").at(0) + `-prev.${nomFile.split(".").at(1)}`;
            let imagePrev = row.DOCDURLX.replace(nomFile, fileprev);
            return { ...row, imgfilex: "images/file-earmark-image.svg", IMGPREVX: imagePrev };
          case "text/xml":
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return { ...row, imgfilex: "images/icon-excel.png" };
          case "docx":
            return { ...row, imgfilex: "images/icon-microsoft-word.png" };
          case "video/mp4":
          case "audio/mpeg":
            return { ...row, imgfilex: "images/file-video.svg" };
          case "text/plain":
            return { ...row, imgfilex: "images/filetype-txt.svg" };
          default:
            return { ...row, imgfilex: "images/default-ext.svg" };
        }
      });
      const dataarr = data.data.map((rows, key) => {
        return (key = false);
      });
      setSelAllChecked(dataarr);
      //Validacion AREA 116
      const dataFilter = dataTkring.filter((row) => {
        let retorno = true;
        if (row.AREAIDXX === 116 && infoUser.GRUPIDXX !== "223") {
          retorno = validateExpiredDocuments(row);
        }
        return retorno;
      });
      if (data.meta.current_page > 1) {
        setDatatkr((prevdata) => prevdata.concat(dataFilter));
      } else {
        setDatatkr(dataFilter);
      }
      setChecSelc([]);
      setCheckDownload([]);
      setAllChecked(false);
      if (isLoggedIn) {
        navigate("/home")
      }
      setShowModalError(false);
    } else {
      setDatatkr([]);
    }
    if (data?.data?.length === 0) {
      setShowModalError(true);
    }
    // eslint-disable-next-line
  }, [data, dispatch]);

  const selectMet = (xMeta) => {
    const page = 1;
    dispatch(setCustomFilters({ ...customFilters, page }));
    let finalfilts = "";
    if (parseInt(xMeta) === 0) {
      finalfilts = filtrosNavar;
    } else if (parseInt(xMeta) === 1) {
      if (cliid !== "") {
        finalfilts = `filters[AREAIDXX]=121&filters[SERIEIDX]=173&filters[TIPOIDXX][IN]=469,470,471,472,473,474,475,476,477,478,479,480,481,482,483,484,485,486,487,488,489,490,491,492,493,494&filters[JSONMETX][->][CLIIDXXX]=${cliid}&filters[REGESTXX]=ACTIVO`;
      }
    } else {
      if (filtrosNavar.includes("SERIEIDX")) {
        const splitfilts = filtrosNavar.split("&");
        let filts = [];
        splitfilts.forEach((row) => {
          if (row.includes("SERIEIDX")) {
            filts.push(`filters[SERIEIDX]=${xMeta}`);
          } else if (!row.includes("TIPOIDXX[IN]")) {
            filts.push(row);
          }
        });
        filts.forEach((elem) => {
          finalfilts += elem + "&";
        });
        finalfilts = finalfilts.slice(0, -1);
      } else {
        finalfilts = `${filtrosNavar}&filters[SERIEIDX]=${xMeta}`;
      }
    }
    dispatch(setFiltersTkr(finalfilts));
    if (numDo) {
      setSerieId(xMeta);
      setHasMore(null);
    }
  };
  const selChecked = (xKey, e) => {
    let arrchck = [];
    setAllChecked(false);
    const valArr = selAllChecked.map((rows, key) => {
      if (xKey === key) {
        return (key = !rows);
      } else {
        return (key = rows);
      }
    });
    if (checSelc.length > 0) {
      arrchck = checSelc.filter((itm) => {
        return itm.toString() !== e.target.value;
      });
      if (e.target.checked) {
        arrchck.push(Number(e.target.value));
      }
    } else {
      arrchck.push(Number(e.target.value));
    }
    if (arrchck.length === 1) {
      permissionUser(arrchck[0]);
    }
    const filterDownload = arrchck.filter((element) => {
      const documents = dataTkr.filter((item) => item.IDARCHXX === element);
      const row = documents[0] ?? {};
      return isDownloadTipo[row.TIPOIDXX];
    });
    if (arrchck.length === filterDownload.length) {
      setCheckDownload(filterDownload);
    } else {
      setCheckDownload([]);
    }
    setChecSelc(arrchck);
    setSelAllChecked(valArr);
  };
  const selAllCheck = () => {
    if (checSelc.length > 0) {
      setChecSelc([]);
    }
    const band = !AllChecked;
    setAllChecked(!AllChecked);
    let filterDownload = [];
    const dataarr = dataTkr.map((rows, key) => {
      if (band) {
        setChecSelc((idarch) => [...idarch, rows.IDARCHXX]);
        if (isDownloadTipo[rows.TIPOIDXX]) {
          filterDownload = [...filterDownload, rows.IDARCHXX];
        }
      } else {
        setChecSelc([]);
        filterDownload = [];
      }
      return (key = band);
    });
    if (dataTkr.length === filterDownload.length) {
      setCheckDownload(filterDownload);
    } else {
      setCheckDownload([]);
    }
    setSelAllChecked(dataarr);
  };
  const downloadSelect = () => {
    setOpenModal(true);
  };
  const tipoDocu = async () => {
    let filts = `filters[JSONMETX][->][DOCNROXX]=${numDo}&filters[REGESTXX]=ACTIVO&per_page=16&page=1&reports=false&environment=GESTOR&groups=TIPOIDXX`;
    if (numDo && serieId && parseInt(serieId) !== 0) {
      filts += `&filters[SERIEIDX]=${serieId}`
    }
    await getFilesxfilters({ filters: filts }, { preferCacheValue: false })
      .unwrap()
      .then((response) => {
        if (response.data.length > 0) {
          const tiposDocs = response.data.map(item => ({
            TIPOIDXX: item.TIPOIDXX,
            TIPODESX: item.TIPODESX,
            EMPNITXX: item.EMPNITXX,
            AREAIDXX: item.AREAIDXX,
            SERIEIDX: item.SERIEIDX
          }));
          setTipoDocs(tiposDocs);
        }
      });
    setOpenTipoDocModal(true);
  };

  useEffect(() => {
    setJsonMeta({});
  }, [selectedTipoDoc]);

  const filtrarDocs = async () => {
    let filts = "";
    if (selectedTipoDoc) {
      filts += `filters[TIPOIDXX]=${selectedTipoDoc}&`
    }
    if (Object.entries(jsonMeta).length > 0) {
      for (let val in jsonMeta) {
        if (jsonMeta[val] !== "") {
          filts += `filters[JSONMETX][->][${val}]=${jsonMeta[val]}&`
        }
      }
    }
    if (filts !== "") {
      filts += `filters[JSONMETX][->][DOCNROXX]=${numDo}&filters[REGESTXX]=ACTIVO&per_page=16&page=1&reports=false&environment=GESTOR&sorts=TYPEXXXX,IDARCHXX`;
    }
    await getFilesxfilters({ filters: filts }, { preferCacheValue: false })
      .unwrap()
      .then((response) => {
        const dataTkring = response.data.map((row) => {
          if (row.JSONMETX?.CLIIDXXX) {
            dispatch(setCliidxx(row.JSONMETX.CLIIDXXX))
          }
          switch (row.TYPEXXXX) {
            case "application/pdf":
              return { ...row, imgfilex: "images/Icon-PDF.png" };
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
              let nomFile = row.PATHFILX.split("/").at(-1);
              let fileprev = nomFile.split(".").at(0) + `-prev.${nomFile.split(".").at(1)}`;
              let imagePrev = row.DOCDURLX.replace(nomFile, fileprev);
              return { ...row, imgfilex: "images/file-earmark-image.svg", IMGPREVX: imagePrev };
            case "text/xml":
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return { ...row, imgfilex: "images/icon-excel.png" };
            case "docx":
              return { ...row, imgfilex: "images/icon-microsoft-word.png" };
            case "video/mp4":
            case "audio/mpeg":
              return { ...row, imgfilex: "images/file-video.svg" };
            case "text/plain":
              return { ...row, imgfilex: "images/filetype-txt.svg" };
            default:
              return { ...row, imgfilex: "images/default-ext.svg" };
          }
        });
        const dataarr = response.data.map((rows, key) => {
          return (key = false);
        });
        setSelAllChecked(dataarr);
        //Validacion AREA 116
        const dataFilter = dataTkring.filter((row) => {
          let retorno = true;
          if (row.AREAIDXX === 116 && infoUser.GRUPIDXX !== "223") {
            retorno = validateExpiredDocuments(row);
          }
          return retorno;
        });
        if (response.meta.current_page > 1) {
          setDatatkr((prevdata) => prevdata.concat(dataFilter));
        } else {
          setDatatkr(dataFilter);
        }
        if (serieId !== 0) {
          setHasMore(response.meta.current_page < response.meta.last_page);
        }
        setOpenTipoDocModal(false);
      });
  }

  /**
   * Funcion para abrir el Visor del Documento
   * @param dataRow
   */
  const viewFiles = (dataRow) => {
    if (dataRow && typeof dataRow === 'object' && 'TYPEXXXX' in dataRow && dataRow.TYPEXXXX === PDF_TYPE) {
      // Convertir los datos a JSON
      const jsonData = JSON.stringify({
        url: dataRow.DOCDURLX,
        pEMPNITXX: dataRow.EMPNITXX,
        pAREAIDXX: dataRow.AREAIDXX,
        pSERIEIDX: dataRow.SERIEIDX,
        pTIPOIDXX: dataRow.TIPOIDXX
      });

      const encryptedData = CryptoJS.AES.encrypt(jsonData, 'GenioVisorPDF').toString();
      const urlBase = `/visor/${encodeURIComponent(encryptedData)}`;
      window.open(urlBase, '_blank');
    } else {
      window.open(dataRow.DOCDURLX, '_blank');
    }
  };

  const viewMetada = (meta, metaMaq) => {
    setMetadaSelc(meta);
    setMetadaMaqSelc(metaMaq);
    setOpMetada(true);
  };
  const viewLine = (dataline) => {
    setLineData(dataline);
    setLinetime(true);
  };
  const prevpage = (page) => {
    dispatch(setCustomFilters({ ...customFilters, page }));
  };

  //Validar permisos del usuario
  const permissionUser = (id) => {
    //Filtro el documento seleccionado
    const document = dataTkr.filter((item) => item.IDARCHXX === id);
    const data = document[0];
    //Filtro los permisos del usuario de acuerdo a la informacion del documento
    const perCompany = permUser.filter((permission) => permission.EMPNITXX === data.EMPNITXX);
    const perArea = perCompany[0].GEST0001.filter((area) => area.AREAIDXX === data.AREAIDXX);
    const perSerie = perArea[0].GEST0002.filter((serie) => serie.SERIEIDX === data.SERIEIDX);
    const perTipo = perSerie[0].GEST0003.filter((tipo) => tipo.TIPOIDXX === data.TIPOIDXX);
    setTiposPermission(perTipo);
    if (perTipo[0]?.DELETEXX === "SI") {
      setPermissionDelete(true);
    } else {
      setPermissionDelete(false);
    }
    if (perTipo[0]?.EDITXXXX === "SI") {
      setPermissionUpdate(true);
    } else {
      setPermissionUpdate(false);
    }
  };
  const editSelect = () => {
    //Filtro el documento seleccionado
    const document = dataTkr.filter((item) => item.IDARCHXX === checSelc[0]);
    setModalEdit(true);
    setDataEdit(document);
  };
  const deleteSelect = () => {
    dispatch(setShowErrors(true));
    dispatch(setTypeMessage("Aviso"));
    dispatch(setErrorMessage({ message: "¿Esta seguro que desea eliminar este Documento?" }));
    setDataDelete({ IDARCHXX: checSelc[0], REGESTXX: "INACTIVO" });
  };

  const viewImages = (dataRow) => {
    let filts = '';
    if (Object.entries(dataRow.JSONMETX).length > 0) {
      for (let val in dataRow.JSONMETX) {
        if (val === "DOCNROXX" && typeof dataRow.JSONMETX[val] === 'object') {
          let nDocNros = dataRow.JSONMETX[val];
          for (let nDo of nDocNros) {
            filts += `filters[JSONMETX][->][${val}]=${nDo}&`
          };
        } else {
          if (dataRow.JSONMETX[val] !== "") {
            filts += `filters[JSONMETX][->][${val}]=${dataRow.JSONMETX[val]}&`
          }
        }
      }
      filts += "filters[REGESTXX]=ACTIVO&filters[TYPEXXXX][IN]=image/png,image/jpg,image/jpeg";
    }
    setJsonFilters(filts)
    setModalView(true);
    setCurrentImage(dataRow.DOCDURLX);
    setDataEdit(dataRow);
  };

  useEffect(() => {
    if (isDelete && dataDelete) {
      let datafing = dataTkr.find((row) => row.IDARCHXX === checSelc[0]);
      let aniocreaup = datafing.REGFECXX;
      updateFile({ REGESTXX: "INACTIVO", IDARCHXX: checSelc[0], anio: aniocreaup.split('-')[0] })
        .unwrap()
        .then((response) => {
          dispatch(setIsConfirm(false));
          dispatch(setShowErrors(true));
          dispatch(setErrorMessage({ message: "Documento eliminado con Exito!" }));
          dispatch(setTypeMessage("Confirmacion"));
        })
        .catch(() => {
          dispatch(setTypeMessage("Error"));
          dispatch(setErrorMessage({ message: "Se presentaron problemas al eliminar el Documento." }));
        });
    }
    // eslint-disable-next-line
  }, [isDelete, dataDelete, updateFile, dispatch]);

  /**
   * Funcion para validar si un documento esta vigente
   * @returns Boolean
   */
  const validateExpiredDocuments = (row) => {
    const FECINICI = new Date();
    const FECHAFIN = row.JSONMETX["FECHAFIN"] ? new Date(`${row.JSONMETX["FECHAFIN"]}T23:59:00`) : FECINICI;
    return FECHAFIN >= FECINICI;
  }

  //Funcion para validar permisos de descarga
  const validatePermissionDownload = useCallback(() => {
    if (infoUser) {
      const { USERPERM = [] } = infoUser;
      let downloadTipo = {};
      USERPERM.forEach(element => {
        const { GEST0001 } = element;
        //Filtro por areas
        GEST0001.forEach(areas => {
          const { GEST0002 } = areas;
          //Filtro por series
          GEST0002.forEach(series => {
            const { GEST0003 } = series;
            //Filtro por tipos
            GEST0003.forEach(tipos => {
              downloadTipo = { ...downloadTipo, [tipos.TIPOIDXX]: tipos?.DOWNLOAD === "SI" };
            });
          });
        });
      });
      setIsDownloadTipo(downloadTipo);
    }
  }, [infoUser]);

  //Hook para marcar los documentos que el usuario tiene para la descarga
  useEffect(() => {
    validatePermissionDownload();
  }, [validatePermissionDownload]);

  return (
    <>
      <Container>
        {isLoading ? (
          <Loadingx />
        ) : (
          <Container fluid>
            <Row>
              <ToggleButtonGroup type="radio" name="options">
                {seriesxFilter.length > 0 && !reports
                  ? seriesxFilter.map((row, key) => {
                    return (
                      <ToggleButton
                        key={key}
                        variant="dark"
                        id={`${row.SERIEIDX}`}
                        value={row.SERIEIDX}
                        onChange={(e) => selectMet(e.target.value)}
                      >
                        {row.SERIEDES}
                      </ToggleButton>
                    );
                  })
                  : reports && <ToggleButton variant="dark">{seriesxFilter}</ToggleButton>}
              </ToggleButtonGroup>
            </Row>
            <br></br>
            {
              numDo ?
                (<Row>
                  <Col className="d-flex justify-content-end">
                    <ToggleButtonGroup type="radio" name="options">
                      <ToggleButton
                        variant="dark"
                        id="tipoDoc"
                        onClick={() => tipoDocu()}
                      >FILTRO POR TIPO
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Col>
                </Row>) : ""
            }
            {
              numDo !== "" ? (
                <Row>
                  <Col className="d-flex justify-content-center">
                    <h3>NUMERO DO: {numDo}</h3>
                  </Col>
                </Row>
              ) : ""
            }
            <br></br>
            {checSelc.length > 0 ? (
              <>
                <Row>
                  {checkDownload.length > 1 && (
                    <Col sm={8} md={4} lg={3}>
                      <Button className="btn-yellow m-2" onClick={() => downloadSelect()}>
                        <i className="bi bi-download"> Descargar Seleccionados</i>
                      </Button>
                    </Col>
                  )}
                  {checSelc.length === 1 && (
                    <>
                      {permissionUpdate && (
                        <Col sm={8} md={4} lg={2}>
                          <Button className="btn-yellow m-2" onClick={() => editSelect()}>
                            <i className="bi bi-pencil"> Editar</i>
                          </Button>
                        </Col>
                      )}
                      {permissionDelete && (
                        <Col sm={8} md={4} lg={3}>
                          <Button className="btn-yellow m-2" onClick={() => deleteSelect()}>
                            <i className="bi bi-trash"> Eliminar</i>
                          </Button>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
                <br></br>
              </>
            ) : (
              ""
            )}
            {reports && (
              <>
                <Row>
                  <Col>
                    {loadReport ? (
                      <>
                        <Button className="btn-yellow m-2">
                          <i className="spinner-border spinner-border-sm" role="status"></i> Enviando Correo...
                        </Button>
                      </>
                    ) : (
                      <>
                        <Col>
                          <Row>
                            <Col sm={12} md={12} lg={4}>
                              <Form.Label>Cliente</Form.Label>
                              <AsyncTypeahead
                                id="client-typeahead"
                                isLoading={isLoadings}
                                onSearch={handleSearch}
                                options={clientOptions}
                                defaultSelected={""}
                                labelKey="label"
                                placeholder="Buscar cliente..."
                                minLength={3}
                                onChange={(selected) => {
                                  if (selected.length > 0) {
                                    setSelectedClient(selected[0]["id"]);
                                  } else {
                                    setSelectedClient("");
                                  }
                                }}
                                filterBy={["label", "id"]}
                              />
                            </Col>
                            <Col sm={12} md={12} lg={4}>
                              <Form.Label>Mes</Form.Label>
                              <Form.Select
                                {...register("MONTHXXX")}
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                              >
                                {months.map((month) => (
                                  <option key={month.value} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                            <Col sm={12} md={12} lg={4}>
                              <Form.Label>Año</Form.Label>
                              <Form.Select
                                {...register("YEARXXXX")}
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                              >
                                {years.map((year) => (
                                  <option key={year.label} value={year.label}>
                                    {year.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </Row>
                          <Row sm={12} md={12} lg={12}>
                            <Col sm={12} md={12} lg={12}>
                              <Form.Label>Correos a Notificar</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                {...register("correos", { required: true })}
                                value={correos}
                                onChange={(e) => setCorreos(e.target.value)}
                              />
                            </Col>
                          </Row>
                          <Row lg={6}>
                            <Button lg={1} className="btn-yellow m-2" onClick={limpiarCampos}>Limpiar</Button>
                            <Button lg={1} className="btn-yellow m-2" onClick={generarReporte}>Generar</Button>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Col>
                </Row>
                <br></br>
              </>
            )}
            {!reports && (
              <>
                <Row>
                  <Col>
                    <Form.Check label={"Seleccionar Todos"} onChange={() => selAllCheck()} checked={AllChecked} />
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col className="d-flex justify-content-end">
                    <span>Items por Pagina: {dataTkr.length}</span>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              {(isLoading || isFetching) && page === 1 ? (
                <Loadingx />
              ) : dataTkr.length > 0 ? (
                <>
                  <InfiniteScroll
                    dataLength={dataTkr.length}
                    hasMore={(hasMore != null) ? hasMore : data.meta.current_page < data.meta.last_page}
                    next={() => prevpage(page + 1)}
                    loader={<Loadingx heigth={100} width={100} />}
                  >
                    {dataTkr.map((row, key) => {
                      return (
                        <div
                          key={`pr${key}`}
                          className="p-3 col-7 col-md-4 col-lg-3 "
                          style={{ display: "inline-block" }}
                        >
                          <Card className="shadow rounded p-3" border="light" style={{ width: "15rem" }}>
                            <Container fluid>
                              <Row>
                                <Col>
                                  {row.TYPEXXXX === "image/png" ||
                                    row.TYPEXXXX === "image/jpeg" ||
                                    row.TYPEXXXX === "image/jpg" ? (
                                    <img
                                      variant="top"
                                      src={`${process.env.REACT_APP_GESTOR_WEB}/${row.imgfilex}`}
                                      width="25"
                                      height="35"
                                      alt="imagen-files"
                                    />
                                  ) : (
                                    <img
                                      variant="top"
                                      src={`${process.env.REACT_APP_GESTOR_WEB}/${row.imgfilex}`}
                                      width="50"
                                      alt="imagen-files"
                                    />
                                  )}
                                </Col>
                                <Col className="d-grid justify-content-end">
                                  <Form.Check
                                    value={`${row.IDARCHXX}`}
                                    onChange={(e) => selChecked(key, e)}
                                    checked={selAllChecked[key]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Card.Body>
                                  <Container>
                                    {row.TYPEXXXX === "image/png" ||
                                      row.TYPEXXXX === "image/jpeg" ||
                                      row.TYPEXXXX === "image/jpg" ? (
                                      <Row>
                                        <Col className="d-grid justify-content-center">
                                          <img variant="top" src={`${row.IMGPREVX}`} alt="No se encontro Prev-Imagen" />
                                        </Col>
                                      </Row>
                                    ) : (
                                      ""
                                    )}
                                    <Row key={`des${key}`}>
                                      <Col className="col-12 text-doc p-0">
                                        <strong>TIPO</strong> : {row.TIPODESX}
                                      </Col>
                                    </Row>
                                    {Object.keys(row.METADATX).map((item, key) => {
                                      // console.log("para: ", row.METADATX[item], " ", typeof row.METADATX[item]);
                                      if (key <= 3) {
                                        let typeMet = "";
                                        typeof row.METADATX[item] === "object" && row.METADATX[item] !== null
                                          ? (typeMet = row.METADATX[item].join(", "))
                                          : (typeMet = row.METADATX[item]);
                                        // Limita el texto a 30 caracteres si no está expandido
                                        typeMet = typeMet.length > 30 ? typeMet.substring(0, 30) + "..."
                                          : typeMet;
                                        return (
                                          <Row key={key}>
                                            <Col className="col-12 text-doc p-0">
                                              <strong>{item}</strong> : {typeMet}
                                            </Col>
                                          </Row>
                                        );
                                      } else {
                                        return "";
                                      }
                                    })}
                                    <Row key={key}>
                                      <Col className="col-12 text-doc p-0">
                                        <strong>FECHA</strong> : {row.REGFECXX} / {row.REGHORXX}
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card.Body>
                              </Row>
                              <Row>
                                <Col>
                                  <img
                                    onClick={() => viewLine(row)}
                                    src={`${process.env.REACT_APP_GESTOR_WEB}/images/icon-timeline.png`}
                                    height={30}
                                    alt="time line"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Col>
                                <Col>
                                  <img
                                    onClick={() => viewMetada(row.METADATX, row.JSONMETX)}
                                    variant="top"
                                    src={`${process.env.REACT_APP_GESTOR_WEB}/images/icon-info.png`}
                                    height={30}
                                    alt="info"
                                    style={{ cursor: "pointer" }}
                                  />
                                </Col>
                                {row.TYPEXXXX === "image/png" ||
                                  row.TYPEXXXX === "image/jpeg" ||
                                  row.TYPEXXXX === "image/jpg" ? (
                                  <Col>
                                    <img
                                      onClick={() => viewImages(row)}
                                      variant="top"
                                      src={`${process.env.REACT_APP_GESTOR_WEB}/images/icon-view.png`}
                                      height={30}
                                      alt="view"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Col>
                                ) : (
                                  <Col>
                                    <img
                                      onClick={() => viewFiles(row)}
                                      variant="top"
                                      src={`${process.env.REACT_APP_GESTOR_WEB}/images/icon-view.png`}
                                      height={30}
                                      alt="view"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Col>
                                )
                                }
                              </Row>
                            </Container>
                          </Card>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </>
              ) : (
                ""
              )}
            </Row>
            {openModal ? (
              <Modadesc openModal={openModal} setOpenModal={setOpenModal} checSelc={checkDownload} dataTkr={dataTkr} />
            ) : (
              ""
            )}
            {openTipoDocModal ? (
              <Modatipx
                openTipoDocModal={openTipoDocModal}
                setOpenTipoDocModal={setOpenTipoDocModal}
                tipoDocs={tipoDocs}
                selectedTipoDoc={selectedTipoDoc}
                setSelectedTipoDoc={setSelectedTipoDoc}
                setJsonMeta={setJsonMeta}
                jsonMeta={jsonMeta}
                filtrarDocs={filtrarDocs}
              />
            ) : (
              ""
            )}
            {opMetada ? (
              <Metadatx
                opMetada={opMetada}
                setOpMetada={setOpMetada}
                metadaSelc={metadaSelc}
                setMetadaSelc={setMetadaSelc}
                metadaMaqSelc={metadaMaqSelc}
              />
            ) : (
              ""
            )}
            {linetime ? (
              <Linetime linetime={linetime} setLinetime={setLinetime} lineData={lineData} setLineData={setLineData} />
            ) : (
              ""
            )}
            {modalEdit && (
              <Trkeditx
                dataEdit={dataEdit}
                modalEdit={modalEdit}
                setModalEdit={setModalEdit}
                tiposPermission={tiposPermission}
              />
            )}
            {modalView && jsonFilters && (<Modaview modalView={modalView} setModalView={setModalView} jsonFilters={jsonFilters} currentImage={currentImage} dataEdit={dataEdit} />)}
            {showModalError && (<Modalerr handleClose={setShowModalError} show={showModalError} />)}
          </Container>
        )}
      </Container>
    </>
  );
};

export default Trkgfile;
