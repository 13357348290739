import { apiSlice } from "../apiSlice";

export const autocomxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDo: builder.query({
      query: (filter) => ({
        url: `/operaciones?filter=` + filter,
        method: "GET",
      }),
    }),
    getDoByClient: builder.query({
      query: (parameters) => ({
        url: `/operationsByClient/${parameters.CLIIDXXX}/${parameters.DOCNROXX}`,
        method: "GET",
      }),
    }),
    getClient: builder.query({
      query: (filter) => ({
        url: `/clientes`,
        params: `per_page=10&fields=CLIIDXXX,CLINOMXX&${filter}`,
        method: "GET",
      }),
    }),
    getEmployee: builder.query({
      query: (filter) => ({
        url: `/empleados?per_page=10&fields=USRIDXXX,EMPNOCOX&filters[REGESTXX]=ACTIVO&filters[EMPNOCOX][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getTransporter: builder.query({
      query: (filter) => ({
        url: `/transportadoras?per_page=10&fields=TRAIDXXX,TRADESXX&filters[REGESTXX]=ACTIVO&filters[TRADESXX][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getProvider: builder.query({
      query: (filter) => ({
        url: `proveedores?per_page=10&fields=PIEIDXXX,PIENOMXX&${filter}`,
        method: "GET",
      }),
    }),
    getAgent: builder.query({
      query: (filter) => ({
        url: `agentes?per_page=10&fields=AGEIDXXX,AGEDESXX&filters[REGESTXX]=ACTIVO&filters[AGEDESXX][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getLineaNaviera: builder.query({
      query: (filter) => ({
        url: `navieras?per_page=10&fields=LINIDXXX,LINDESXX&filters[REGESTXX]=ACTIVO&filters[LINDESXX][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getOpMap: builder.query({
      query: (filter) => ({
        url: `/operacionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getBkMap: builder.query({
      query: (filter) => ({
        url: `/reservacionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getQtMap: builder.query({
      query: (filter) => ({
        url: `/cotizacionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getPoMap: builder.query({
      query: (filter) => ({
        url: `/posicionMap?filter=` + filter,
        method: "GET",
      }),
    }),
    getClientMap: builder.query({
      query: (filter) => ({
        url: `/clientes?per_page=10&fields=CLIIDXXX,CLINOMXX&filters[MAPESTXX]=ACTIVO&filters[CLINOMXX][LIKE]=${filter}%`,
        method: "GET",
      }),
    }),
    getReferencia: builder.query({
      query: (filter) => ({
        url: `/referencia`,
        params: `per_page=10&fields=cliidxxx,prorefxx,prodesxx&filters[cliidxxx]=${filter.CLIIDXXX}&filters[regestxx]=ACTIVO&filters[prorefxx][LIKE]=${filter.value}%`,
        method: "GET",
      }),
    }),
    getManifiesto: builder.query({
      query: (filter) => ({
        url: `/manifiesto`,
        params: `fields=maniidxx,manititu&filters[maniidxx][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getComprobante: builder.query({
      query: (filter) => ({
        url: `/comprobante`,
        params: `fields=manicomp,manititu&filters[manicomp][LIKE]=${filter}`,
        method: "GET",
      }),
    }),
    getClientReport: builder.query({
      query: (filter) => ({
        url: `/getClientReportById/` + filter,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetDoQuery, useLazyGetDoByClientQuery } = autocomxApiSlice;
export const { useLazyGetClientQuery } = autocomxApiSlice;
export const { useLazyGetEmployeeQuery } = autocomxApiSlice;
export const { useLazyGetTransporterQuery } = autocomxApiSlice;
export const { useLazyGetProviderQuery } = autocomxApiSlice;
export const { useLazyGetAgentQuery } = autocomxApiSlice;
export const { useLazyGetLineaNavieraQuery } = autocomxApiSlice;
export const { useLazyGetOpMapQuery } = autocomxApiSlice;
export const { useLazyGetBkMapQuery } = autocomxApiSlice;
export const { useLazyGetQtMapQuery } = autocomxApiSlice;
export const { useLazyGetPoMapQuery } = autocomxApiSlice;
export const { useLazyGetReferenciaQuery } = autocomxApiSlice;
export const { useLazyGetManifiestoQuery } = autocomxApiSlice;
export const { useLazyGetComprobanteQuery } = autocomxApiSlice;
export const { useLazyGetClientReportQuery } = autocomxApiSlice;
