import { useEffect, useRef, useState } from "react";
import { Accordion, Container, Form, Nav, NavDropdown, Navbar, Button, Col } from "react-bootstrap";
import { getpermUser, getinfoUser, getisLoggedIn } from "../../libs/redux/slices/Loginxxx/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setFiltersTkr,
  resetfilters,
  setFilterSeries,
  setReports,
  setCustomFilters,
  getCustomFilters,
  setFiltsNav,
  setReset,
  getNavBar,
  setNavBar,
  setNumDo,
  setCliidxx } from "../../libs/redux/slices/Trkgfile/trkgfileSlice";
import { setAreaSelected, setEmpresaSelected } from "../../libs/redux/slices/autocomxSlice/autocomSlice";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { useGetSeriesxFiltersMutation } from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import Metadata from "../Metadata/Metadata";
import { useParams } from "react-router-dom";

const Navbarxx = ({closeSesion}) => {
  const dispatch = useDispatch();
  const form = useRef();
  const navDrown = useSelector(getNavBar);
  const permUser = useSelector(getpermUser);
  const infoUser = useSelector(getinfoUser);
  const [ bandUSe, setBandUse] = useState(true);
  const [ empByUser, setEmpByUser] = useState({});
  const [ empSelect, setEmpSelect] = useState({});
  const [ areaByEmp, setAreaByEmp ] = useState([]);
  const [ areaSelect, setAreaSelect ] = useState({});
  const [ serieByEmp, setSerieByEmp ] = useState([]);
  const [ tipDocByEmp, setTipDocByEmp ] = useState([]);
  const [ tipoSelect, setTipoSelect ] = useState({});
  const [ metadat, setMetadat] = useState([]);
  const [jsonmetx, setJsonmetx] = useState({});
  const [extensions, setExtensions] = useState([]);
  const [ getseriaxFilters ] = useGetSeriesxFiltersMutation();
  const [ banReports, setBanReports ] = useState(false);
  const customFilters = useSelector(getCustomFilters);
  const [ usrFoto, setUsrFoto] = useState("/images/picture_defaul.png");
  const [multiSelecExt, setMultiSelecExt] = useState([]);
  const [inputDateStart, setInputDateStart] = useState("");
  const [inputDateEnd, setInputDateEnd] = useState("");
  const [filterDate, setFilterDate ] = useState(false);
  const isLoggedIn = useSelector(getisLoggedIn);
  const params =useParams();
  const {
    register,
    handleSubmit,
    setValue
  } = useForm();

  //Estado para Habilitar el campo Extension del Documento
  const [isSeeDocExtension, setIsSeeDocExtension] = useState(false);

  useEffect (() => {
    if (bandUSe && isLoggedIn) {
      dispatch(setReset(false));
      setBandUse(false);
      if (permUser.length > 0) {
        let permEmpr = permUser.map(row => {
          return { "EMPNITXX": row.EMPNITXX, "EMPDESXX": row.EMPDESXX};
        })
        setEmpByUser(permEmpr);
      }
      if (infoUser) {
        const newExt = infoUser.EXTENSXX.split(",");
        const otionsExt = newExt.map((ext) => {
          return { id: ext, label: ext };
        });
        setExtensions(otionsExt);
      }
    }
    if (isLoggedIn && infoUser?.USRFOTOX !== "") {
      setUsrFoto(infoUser.USRFOTOX);
    }
    // eslint-disable-next-line
  }, [setBandUse, bandUSe, permUser, setEmpByUser, infoUser, setExtensions, setUsrFoto, dispatch])

  const areaByEmpr = (xEmp) => {
    setAreaByEmp([]);
    setSerieByEmp([]);
    setTipDocByEmp([]);
    setMetadat([]);
    setJsonmetx({});
    setValue("AREAIDXX", "");
    setValue("SERIEIDX", "");
    setValue("TIPOIDXX", "");
    if (xEmp !== "") {
      const searchEmp = permUser.find((items) => items.EMPNITXX === xEmp);
      setEmpSelect(searchEmp)
      let areas = searchEmp.GEST0001.map(item => {
        return {"AREAIDXX": item.AREAIDXX, "AREADESX": item.AREADESX};
      })
      setAreaByEmp(areas)
      filterDateByCompany(xEmp);
    }
  }
  const serieByEmprArea = (xArea) => {
    setTipDocByEmp([]);
    setSerieByEmp([]);
    setMetadat([]);
    setJsonmetx({});
    setValue("SERIEIDX", "");
    setValue("TIPOIDXX", "");
    if (xArea !== "") {
      const searSeries = empSelect.GEST0001.find((items) => items.AREAIDXX === Number(xArea));
      setAreaSelect(searSeries)
      let series = searSeries.GEST0002.map(item => {
        return {"SERIEIDX": item.SERIEIDX, "SERIEDES": item.SERIEDES};
      })
      setSerieByEmp(series);
    }
  }
  const TipoDocByEmprArea = (xSerie) => {
    setMetadat([]);
    setJsonmetx({});
    setValue("TIPOIDXX", "");
    if (xSerie !== "") {
      const searTipDoc = areaSelect.GEST0002.find((items) => items.SERIEIDX === Number(xSerie));
      setTipoSelect(searTipDoc)
      let tipDocs = searTipDoc.GEST0003.map(item => {
        return {"TIPOIDXX": item.TIPOIDXX, "TIPODESX": item.TIPODESX};
      })
      setTipDocByEmp(tipDocs);
    }
  }
  const metadatosPerm = (xTipoDoc) => {
    if (xTipoDoc !== "") {
      const metadats = tipoSelect.GEST0003.find((items) => items.TIPOIDXX === Number(xTipoDoc));
      setMetadat(metadats.GEST0004);
    }
  }

  const verExtendion = (value) => {
    setIsSeeDocExtension(value !== "");
  }

  const resetFilters = () => {
    dispatch(setReset(true));
    dispatch(resetfilters());
    dispatch(setReports(false));
    dispatch(setNavBar(true));
    dispatch(setNumDo(""));
    dispatch(setCliidxx(""));
  }
  const sendInfo = async (formData) => {
    const page = 1;
    dispatch(setCustomFilters({...customFilters, page}));
    dispatch(setFilterSeries([]));
    dispatch(setNumDo(""));
    dispatch(setCliidxx(""));
    let filts = "";
    for (let key in formData) {
      if (formData[key] !== "") {
        filts += `filters[${key}]=${formData[key]}&`
      }
    }

    if (banReports) {
      filts = "";
      for (let key in formData) {
        if (formData[key] !== "") {
          filts += `${key}=${formData[key]}-`
        }
      }
      dispatch(setFilterSeries(["REPORTE GENERALES"]));
      filts = filts.slice(0, -1);
      dispatch(setFiltersTkr(filts));
      dispatch(setFiltsNav(filts));
      dispatch(setNavBar(false));
    } else {
      if (Object.entries(jsonmetx).length > 0 ) {
        for (let val in jsonmetx) {
          if (jsonmetx[val] !== "") {
            filts += `filters[JSONMETX][->][${val}]=${jsonmetx[val]}&`
          }
        }
      }
      if (multiSelecExt.length > 0) {
        filts += `filters[TYPEXXXX][IN]=${multiSelecExt}&`
      }
      if (inputDateStart !== "" && inputDateEnd !== "") {
        filts += `filters[REGFECXX][BETWEEN][0]=${inputDateStart}&filters[REGFECXX][BETWEEN][1]=${inputDateEnd}&`
      } else if (inputDateStart !== "") {
        filts += `filters[REGFECXX]=${inputDateStart}&`
      } else if (inputDateEnd !== "") {
        filts += `filters[REGFECXX]=${inputDateEnd}&`
      }
      if (filts !== "") {
        filts += "filters[REGESTXX]=ACTIVO"
        dispatch(setFiltersTkr(filts));
        dispatch(setFiltsNav(filts));
        dispatch(setNavBar(false));
        await getseriaxFilters({
          filters: filts
        }).unwrap().then((response) => {
          const totalData = response.data.concat({"SERIEIDX": 0, "SERIEDES": "GENERAL"});
          totalData.sort((key, val) => key.SERIEIDX - val.SERIEIDX);
          dispatch(setFilterSeries(totalData));
        });
      }
    }
  }
  const updateJsonmetx = (key, value) => {
    const newJsonmetx = { ...jsonmetx };
    newJsonmetx[key] = value;
    setJsonmetx(newJsonmetx);
  };
  const tipeReport = (tipe) => {
    dispatch(resetfilters());
    if (tipe !== "") {
      dispatch(setReports(true))
      setBanReports(true);
    } else {
      setBanReports(false);
      dispatch(setReports(false));
    }
  }

  const filterDateByCompany = (company) => {
    switch (company) {
      case "830104929": // LOGISTICA REPREMUNDO SAS
      case "860079024": // REPREMUNDO SAS
      case "900698414": // REPREMUNDO INDUSTRIAL DE SERVICIOS
      case "901374991": // REPREMUNDO INDUSTRIAL DEL VALLE
        setFilterDate(true);
        break;
      default:
        setFilterDate(false);
        break;
    }
  }
  return (
    <Navbar className="navPrinc">
      <Container>
          <NavbarCollapse>
            <NavDropdown
              size="sm"
              title= {
                <img src={`${usrFoto}`}
                  className="rounded"
                  width={35}
                  alt="Nav Icon Users"
                />
              }
            >
              <NavDropdown.Item>
                <h6 data-testid="nombre">
                  {infoUser?.USRPNOMX ?? "Nombre"} {infoUser?.USRPAPEX ?? "Apellido"}
                </h6>
              </NavDropdown.Item>
              <NavDropdown.Item>
              <Col className="d-flex justify-content-center">
                <Button variant="secondary" onClick={() => closeSesion()}>
                  <i className="bi bi-box-arrow-in-right" /> <span>Cerrar Sesion</span>
                </Button>
              </Col>
              </NavDropdown.Item>
            </NavDropdown>
          </NavbarCollapse>
          <NavbarCollapse>
          <img src="/images/LOGO .png"
                height={120}
                className="imageNav"
                alt="Nav Genio Documental"
              />
          </NavbarCollapse>
          <NavbarCollapse >
            { !params.user && infoUser?.USRIDXXX !== 10000 ? (
              <>
                <Nav className="ml-auto">
                <NavDropdown
                  title= {
                    <img src="/images/icon-search.png"
                      width={35}
                      onClick={() => dispatch(setNavBar(!navDrown))}
                      alt="Nav Icon Search"
                    />
                  }
                  show={navDrown}
                >
                  <Container className="color-cont rounded navFilters p-0" >
                    <Form ref={form} onSubmit={handleSubmit(sendInfo)}>
                      <Accordion flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>EMPRESA</Accordion.Header>
                          <Accordion.Body>
                            <Form.Select
                              { ...register("EMPNITXX") }
                              onChange={(e) =>
                                {
                                  areaByEmpr(e.target.value)
                                  dispatch(setEmpresaSelected(e.target.value));
                                  setIsSeeDocExtension(false);
                                }
                              }
                              >
                              <option value="">SELECCIONE</option>
                              {
                                empByUser.length && (
                                  empByUser.map((item, key) => {
                                    return (
                                      <option key={key} value={item.EMPNITXX}>{item.EMPDESXX}</option>
                                    )
                                  })
                                )
                              }
                            </Form.Select>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion flush>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>PROCESO</Accordion.Header>
                          <Accordion.Body>
                            <Form.Select
                              { ...register("AREAIDXX") }
                                onChange={(e) => {
                                  serieByEmprArea(e.target.value);
                                  dispatch(setAreaSelected(e.target.value));
                                  setIsSeeDocExtension(false);
                                }
                              }
                              >
                              <option value="">SELECCIONE</option>
                              {
                                areaByEmp.map((item, key) => {
                                  return (
                                    <option key={key} value={item.AREAIDXX}>{item.AREADESX}</option>
                                  )
                                })
                              }
                            </Form.Select>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion flush>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>SERIE</Accordion.Header>
                          <Accordion.Body>
                            <Form.Select
                              { ...register("SERIEIDX") }
                              onChange={(e) => {
                                TipoDocByEmprArea(e.target.value);
                                setIsSeeDocExtension(false);
                              }}
                              >
                              <option value="">SELECCIONE</option>
                              {
                                serieByEmp.map((item, key) => {
                                  return (
                                    <option key={key} value={item.SERIEIDX}>{item.SERIEDES}</option>
                                  )
                                })
                              }
                            </Form.Select>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion flush>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>TIPO DE DOCUMENTO</Accordion.Header>
                          <Accordion.Body>
                            <Form.Select
                              { ...register("TIPOIDXX") }
                              onChange={(e) => {
                                metadatosPerm(e.target.value);
                                verExtendion(e.target.value);
                              }}
                              >
                              <option value="">SELECCIONE</option>
                              {
                                tipDocByEmp.map((item, key) => {
                                  return (
                                    <option key={key} value={item.TIPOIDXX}>{item.TIPODESX}</option>
                                  )
                                })
                              }
                            </Form.Select>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {
                        (metadat && metadat.length > 0) ? (
                          metadat.map((itms, key) => {
                            return (
                              <Accordion key={key} flush>
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header>{itms.METDESXX}</Accordion.Header>
                                  <Accordion.Body>
                                    <Metadata metadat={itms} setJsonmetx={updateJsonmetx} consult={true} jsonmetx={jsonmetx}/>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            )
                          })
                        ) : ""
                      }
                      {
                        isSeeDocExtension ? (
                          <Accordion flush>
                            <Accordion.Item eventKey="5">
                              <Accordion.Header>EXTENSION DEL DOCUMENTO</Accordion.Header>
                              <Accordion.Body>
                                <Form.Select
                                  value={multiSelecExt}
                                  multiple
                                  onChange={(e) => {
                                    setMultiSelecExt([]);
                                    const extSelected = [];
                                    for (let i = 0; i < e.target.options.length; i++) {
                                      if (e.target.options[i].selected && e.target.options[i].value !== "") {
                                        extSelected.push(e.target.options[i].value);
                                      }
                                    }
                                    setMultiSelecExt(extSelected);
                                  }}
                                >
                                  <option key={"option"} value={""}>SELECCIONE</option>
                                  {
                                    extensions.map((ext, index) => (
                                      <option key={index} value={ext.label}>
                                        {ext.label}
                                      </option>
                                    ))
                                  }
                                </Form.Select>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        ) : ""
                      }
                      <Accordion flush>
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>REPORTES</Accordion.Header>
                          <Accordion.Body>
                            <Form.Select
                              { ...register("REPORTSX") }
                              onChange={(e) => tipeReport(e.target.value)}
                            >
                              <option value="">SELECCIONE</option>
                              <option value="GENERAL">REPORTE GENERAL</option>
                            </Form.Select>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {
                        (filterDate) ? (
                          <>
                            <Accordion flush>
                              <Accordion.Item eventKey="7">
                                <Accordion.Header>FECHA</Accordion.Header>
                                <Accordion.Body>
                                  <Form.Label>Desde:</Form.Label>
                                  <Form.Control
                                  type="date"
                                  onChange={(e) => setInputDateStart(e.target.value) }
                                  />
                                  <Form.Label>Hasta:</Form.Label>
                                  <Form.Control
                                  type="date"
                                  onChange={(e) => setInputDateEnd(e.target.value) }
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        ) : ""
                      }
                      <Container className="d-grid gap-2 d-md-flex p-2 justify-content-md-end">
                        {
                          banReports ? (
                            <Button type="submit" className="btn-login">
                              Generar Reporte
                            </Button>
                          ) : (
                            <Button type="submit" className="btn-login">
                              Buscar
                            </Button>
                          )
                        }
                      </Container>
                    </Form>
                  </Container>
                </NavDropdown>
                </Nav>
                <Nav className="ml-auto">
                  <img src="/images/icon-reload.png"
                    width={35}
                    onClick={() => resetFilters()}
                    alt="Nav Icon Reload"
                  />
                </Nav>
              </>
            ) : ""
          }
          </NavbarCollapse>
      </Container>
    </Navbar>
  )
 }

 export default Navbarxx;
